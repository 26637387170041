import React from 'react'
import { format } from 'd3-format'
import Table from '../Table'
import { useTranslation } from 'react-i18next'

const IG21Table = ({ regions }) => {
  const { t } = useTranslation()

  const formatThous = format(',')

  // const wrangleData = (data) => {
  //   const allData = Object.entries(data)
  //     .map(([key, value]) => {
  //       //gets the years that have data so we know what columns to make
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]
  //       if (!keysToExclude.includes(key) && value !== null) {
  //         const year = +key.slice(-4)
  //         return { year, value }
  //       } else return null
  //     })
  //     .filter((item) => item)
  const BASELINE_YEAR = 2019
  let years = []
  const wrangleData = (regions) => {
    const formattedData = regions.reduce((acc, d) => {
      if (d.YEAR !== BASELINE_YEAR) years.push(d.YEAR)
      acc[d.YEAR === BASELINE_YEAR ? 'Baseline' : d.YEAR] = formatThous(
        d.VALUE_NUM
      )
      return acc
    }, {})

    return [{ ...formattedData, label: t('number of zero dose children') }]
  }

  const ig21Data = wrangleData(regions)
  const data = React.useMemo(() => ig21Data, [ig21Data])

  // const baseHeader = [
  //   {
  //     Header: '',
  //     accessor: 'label' // accessor is the "key" in the data
  //   }
  // ]

  const yearHeaders = Object.keys(ig21Data[0])
    .map((key) => {
      if (key === 'label' || key === 'Baseline') return null
      return {
        Header: `${key}`,
        accessor: `${key}`
      }
    })
    .filter((item) => item)

  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: '',
        accessor: 'label' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])

  return (
    <Table
      data={data}
      columns={columns}
      description={t(
        'A table that shows the number of zero dose children each year.'
      )}
    />
  )
}

export default IG21Table
