import React from 'react'
import styled from 'styled-components'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { BsFillExclamationDiamondFill } from 'react-icons/bs'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'

const StyledCard = styled.div`
  padding: 2.2rem;
  width: 19.375rem;
  background-color: var(--paleBlue);
  border-radius: ${({ regional, currYear }) =>
    regional || currYear < 2021 ? '0.75rem' : '0.75rem .75rem 0 0'};
  margin: 1rem;
  height: 100%;
  text-align: center;
  position: relative;
`

const StyledText = styled.div`
  p {
    color: var(--baseBlue);
    font-size: 1.5rem;
    strong {
      font-size: 2.75rem;
    }
  }
`
const Line = styled.hr`
  height: 1px;
  border-width: 0;
  background-color: var(--baseBlue);
  margin: 1rem -0.5rem;
`
const TargetIndicator = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 55px;
  position: absolute;
  bottom: -55px;
  border: 1px solid var(--lightestGray);
  border-radius: 0 0 0.75rem 0.75rem;
  margin: 0 -2.2rem;
  padding: 0 0.5rem;
  font-size: 1.1rem;
`
const Callout = ({
  content,
  sectionId,
  data,
  onTarget,
  regional,
  currYear
}) => {
  const { t } = useTranslation()
  const sectionsWithExtraContent = [] //['2.1', '2.2'] -- take out increase/decrease percentages for now
  return (
    <StyledCard regional={regional} currYear={currYear}>
      <>
        <StyledText dangerouslySetInnerHTML={{ __html: content.calloutRt }} />
        {sectionsWithExtraContent.includes(sectionId) && (
          <>
            <Line />
            <StyledText
              dangerouslySetInnerHTML={{ __html: content.calloutRtPt2 }}
            />
            <StyledText>
              <p>
                <strong>
                  {data}
                  {sectionId === '2.1' && '%'}
                </strong>
              </p>
            </StyledText>
            {sectionId === '2.1' && (
              <StyledText>
                <p>{data > 0 ? 'increase' : 'decrease'}</p>
              </StyledText>
            )}
          </>
        )}
      </>
      {!regional && currYear >= 2021 && (
        <TargetIndicator>
          {onTarget ? (
            <>
              <IoCheckmarkCircle color={'#4681de'} size={42} />
              {t('on track toward 2030 target')}
            </>
          ) : (
            <>
              <BsFillExclamationDiamondFill color={'#d96038'} size={42} />
              {t('off track toward 2030 target')}
            </>
          )}
        </TargetIndicator>
      )}
    </StyledCard>
  )
}

export default Callout
