import * as React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { useChartDimensions } from '../../hooks/dimensions'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import ChartProvider from '../ChartContext'
import { useMediaQuery } from 'react-responsive'
import Callout from '../Callout'
import IG21Chart from './IG21Chart'
import { NUM_YEARS, PRINT_DIMENSIONS } from '../../utils/const'
import ImageExport from '../ImageExport'
//import { wrangle21Data } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'

const Figure = styled.figure`
  flex: 1 1;
  width: 75%;
  height: 65vh;
`
const Flex = styled.div`
  display: flex;
  // justify-content: space-between;
  margin-top: 3rem;
`
const Container = styled.div`
  margin-bottom: 4rem;
  ${Mq.md} {
    margin-bottom: 9rem;
  }
`
const IG21ChartWrapper = ({ regions, content, sectionId, title }) => {
  const { t } = useTranslation()
  const [ref, dimensions] = useChartDimensions({
    marginTop: 16,
    marginRight: 32,
    marginBottom: 84,
    marginLeft: 48
  })
  // const wrangleData = (data) => {
  //   let maxYearWithData
  //   const formattedData = Object.entries(data)
  //     .map(([key, value]) => {
  //       const keysToExclude = ['memberState', 'iSO3', 'id', 'region']
  //       if (!keysToExclude.includes(key)) {
  //         const year = +key.slice(-4)
  //         if (value !== null) {
  //           maxYearWithData = year
  //         }
  //         return { year, value: +value }
  //       } else return null
  //     })
  //     .filter((item) => item)

  //   return Object.assign(formattedData, { maxYearWithData })
  // }

  const ig21Data = regions.sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1)) //wrangle21Data(regions)
  const baseline = ig21Data[0].VALUE_NUM
  const goal = baseline / 2
  const mostRecentValue = ig21Data[ig21Data.length - 1].VALUE_NUM
  const delta = mostRecentValue - baseline
  const numYearsElapsed = ig21Data[ig21Data.length - 1].YEAR - 2020
  const goalPerYear = goal / NUM_YEARS
  const TotalNeededToBeOnTrack = -(numYearsElapsed * goalPerYear)
  const onTarget = delta > 0 ? false : delta <= TotalNeededToBeOnTrack

  const deltaAsPercentOfBaseline = ((delta / baseline) * 100).toFixed(2)
  const currYear = ig21Data[ig21Data.length - 1].YEAR
  // console.log('gpy', goalPerYear)
  // console.log('mrv', mostRecentValue)
  // console.log('delta', delta)
  // console.log('TotalNeededToBeOnTrack', TotalNeededToBeOnTrack)
  // console.log('onTarget', onTarget)
  const matches = useMediaQuery({ query: '(max-width: 900px)' })

  return (
    <>
      <ImageExport title={title} dataSource={content.dataSourceNotesRt}>
        <Flex>
          {/* <Figure
            ref={ref}
            role="img"
            alt="Bar chart showing the trend of number of zero dose children by year with the goal of 50% reduction."
          > */}
          <ChartProvider dimensions={PRINT_DIMENSIONS}>
            <IG21Chart
              formattedData={ig21Data}
              data={deltaAsPercentOfBaseline}
              onTarget={onTarget}
            />
          </ChartProvider>
          {/* </Figure> */}

          <Callout
            content={content}
            sectionId={sectionId}
            data={deltaAsPercentOfBaseline}
            onTarget={onTarget}
            currYear={currYear}
          />
        </Flex>
      </ImageExport>

      <Container>
        <Flex>
          <Figure
            ref={ref}
            role="img"
            alt={t(
              'Bar chart showing the trend of number of zero dose children by year with the goal of 50% reduction.'
            )}
          >
            <ChartProvider dimensions={dimensions}>
              <IG21Chart
                formattedData={ig21Data}
                data={deltaAsPercentOfBaseline}
                onTarget={onTarget}
              />
            </ChartProvider>
          </Figure>
          {!matches ? (
            <Callout
              content={content}
              sectionId={sectionId}
              data={deltaAsPercentOfBaseline}
              onTarget={onTarget}
              currYear={currYear}
            />
          ) : null}
        </Flex>
        {matches ? (
          <Callout
            content={content}
            sectionId={sectionId}
            data={deltaAsPercentOfBaseline}
            onTarget={onTarget}
            currYear={currYear}
          />
        ) : null}
      </Container>
    </>
  )
}
export default IG21ChartWrapper
