import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import Notes from '../components/Notes'
import PageHeader from '../components/PageHeader'
import IG21ChartWrapper from '../components/IG21/IG21ChartWrapper'
import IG21Table from '../components/IG21/IG21Table'
import Footer from '../components/Footer'
import {
  LOCATION_LABELS,
  WHO_LOCATION_OPTIONS,
  INCOME_GROUPS,
  UNI_REGIONS,
  INC_LOCATION_OPTIONS,
  UNI_LOCATION_OPTIONS,
  REGION_LOOKUP
} from '../utils/const'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'
import RegionInfo from '../components/RegionInfo'
import Disclaimer from '../components/Disclaimer'

const Ig21Page = ({ data, pageContext }) => {
  const { t } = useTranslation()

  const { sectionId } = pageContext
  const { content, footerContent } = data

  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')

  const isInc = INCOME_GROUPS.includes(reg)
  const isUni = UNI_REGIONS.includes(reg)
  const isGbl = reg === 'GBL' || !reg
  const regionLabel = isGbl ? t('global') : t(LOCATION_LABELS[reg])

  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS}
          incOptions={INC_LOCATION_OPTIONS}
          uniOptions={UNI_LOCATION_OPTIONS}
        />

        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>
        <IG21ChartWrapper
          // regions={
          //   isGbl
          //     ? data.global
          //     : isInc
          //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
          //     : isUni
          //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
          //     : data.regions.nodes.find((d) => d.iSO3 === reg)
          // }
          regions={data.regions.nodes.filter(
            (d) => d.CODE === REGION_LOOKUP[reg]
          )}
          sectionId={sectionId}
          content={content}
          //regional={reg !== 'GBL'} //if regional, callout will not show target indicator
          title={`${content.chartTitle}—${regionLabel}`}
        />

        <IG21Table
          // regions={
          //   isGbl
          //     ? data.global
          //     : isInc
          //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
          //     : isUni
          //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
          //     : data.regions.nodes.find((d) => d.iSO3 === reg)
          // }
          regions={data.regions.nodes.filter(
            (d) => d.CODE === REGION_LOOKUP[reg]
          )}
        />
        {!isGbl && (isUni || isInc) && <Disclaimer />}
        {!isGbl && <RegionInfo reg={reg} />}
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Ig21Page

// iso3 is null for global page but this works because it is the only null one
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleIg21CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      calloutRt
      calloutRtPt2
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }

    regions: allRegions(filter: { IA2030_IND: { eq: "IG2.1" } }) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        VALUE_NUM
      }
    }
  }
`

//old queries
// global: googleIg21Sheet(regionType: { eq: "GBL" }) {
//   id
//   iSO3
//   region
//   memberState
//   zeroDose2019
//   zeroDose2020
//   zeroDose2021
//   zeroDose2022
//   zeroDose2023
//   zeroDose2024
//   zeroDose2025
//   zeroDose2026
//   zeroDose2027
//   zeroDose2028
//   zeroDose2029
// }
// regions: allGoogleIg21Sheet(filter: { regionType: { eq: "REG" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     zeroDose2019
//     zeroDose2020
//     zeroDose2021
//     zeroDose2022
//     zeroDose2023
//     zeroDose2024
//     zeroDose2025
//     zeroDose2026
//     zeroDose2027
//     zeroDose2028
//     zeroDose2029
//   }
// }
// income: allGoogleIg21Sheet(filter: { regionType: { eq: "INC" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     incomeGroup
//     memberState
//     zeroDose2019
//     zeroDose2020
//     zeroDose2021
//     zeroDose2022
//     zeroDose2023
//     zeroDose2024
//     zeroDose2025
//     zeroDose2026
//     zeroDose2027
//     zeroDose2028
//     zeroDose2029
//   }
// }
// unicef: allGoogleIg21Sheet(filter: { regionType: { eq: "UNI" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     uNICEFRegion
//     memberState
//     zeroDose2019
//     zeroDose2020
//     zeroDose2021
//     zeroDose2022
//     zeroDose2023
//     zeroDose2024
//     zeroDose2025
//     zeroDose2026
//     zeroDose2027
//     zeroDose2028
//     zeroDose2029
//   }
// }
