import React from 'react'
import styled from 'styled-components'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { scaleLinear, scaleBand } from 'd3-scale'
import { format } from 'd3-format'
import { range } from 'd3-array'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useDimensionsContext } from '../ChartContext'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

const StyledXAxis = styled.g`
  font-size: 1rem;
  fill: var(--darkGray);
  text-anchor: middle;
  }
  ${Mq.xl} {
    font-size: 0.75rem;
  }
`

const XAxis = ({ x, maxYear }) => {
  const { t } = useTranslation()
  //const currYear = new Date().getFullYear()
  const xTicks = [2019, ...range(2021, 2032)].flat()

  const dimensions = useDimensionsContext()
  const matches = useMediaQuery({ query: '(max-width: 1080px)' }) //Lg

  return (
    <StyledXAxis
      className="axis"
      transform={`translate(0, ${dimensions.boundedHeight})`}
    >
      {xTicks.map((tick, i) => {
        return (
          <React.Fragment key={tick}>
            <g
              style={{
                transform: `translate(${x(tick) + x.bandwidth() / 2}px, 0)`
              }}
            >
              {tick === 2019 && matches ? (
                <text y={25} dy="0.71em" fontWeight="bold">
                  {`${t('BL')}*`}
                </text>
              ) : tick === 2019 && !matches ? (
                <>
                  <text y={25} dy="0.71em" fontWeight="bold">
                    {t('Base')}
                  </text>
                  <text y={47} dy="0.71em" fontWeight="bold">
                    {t('line')}
                  </text>
                </>
              ) : (
                <text
                  y={25}
                  dy="0.71em"
                  opacity={tick <= maxYear || tick === 2030 ? 1 : 0}
                >
                  {tick === 2030
                    ? t('TargetALT')
                    : dimensions.width < 500
                    ? "'" + tick.toString().slice(-2)
                    : tick}
                </text>
              )}
            </g>
            {tick === 2019 && matches ? (
              <g
                style={{
                  transform: `translate(${x(tick)}px, 0)`
                }}
              >
                <text y={60} dy="0.71em" fontWeight="bold" textAnchor="start">
                  {`*${t('BL')} = ${t('Baseline')}`}
                </text>
              </g>
            ) : null}
          </React.Fragment>
        )
      })}
    </StyledXAxis>
  )
}

const StyledYAxis = styled.g`
  font-size: 1rem;
  fill: var(--darkGray);
  dominant-baseline: middle;
  text-anchor: start;
  .solid-line {
    stroke: var(--lightGray);
  }
  ${Mq.md} {
    font-size: 0.9rem;
  }
`

const YAxis = ({ y }) => {
  const dimensions = useDimensionsContext()
  const yTicks = y.ticks(9)

  // [
  //   0, 2000000, 4000000, 6000000, 8000000, 10000000, 12000000, 14000000,
  //   16000000, 18000000
  // ]

  return (
    <StyledYAxis>
      {yTicks.map((tick, i) => {
        return (
          <g
            key={tick}
            style={{
              transform: `translate(0, ${y(tick)}px)`
            }}
          >
            <line className="solid-line" x2={dimensions.boundedWidth} />
            <text x={-10} textAnchor={'end'}>{`${tick / 1000000}M`}</text>
          </g>
        )
      })}
    </StyledYAxis>
  )
}

const TargetBar = styled.rect`
  fill-opacity: 0;
  stroke-width: 3px;
  stroke-dasharray: 5;
  stroke: var(--brightBlue);
`
const TargetLabel = styled.text`
  fill: var(--brightBlue);
  font-size: 1rem;
  font-weight: bold;
  text-anchor: middle;
`
const Total = styled.text`
  fill: var(--brightBlue);
  font-size: 0.8rem;
  font-weight: bold;
  text-anchor: middle;
  ${Mq.md} {
    font-size: 0.7rem;
  }
  ${Mq.xs} {
    font-size: 0.6rem;
  }
`
const Line = styled.line`
  stroke: ${(props) => props.color};
  stroke-width: 3px;
`

const IG21Chart = ({ formattedData, onTarget, data }) => {
  const dimensions = useDimensionsContext()
  // const { t } = useTranslation()
  //need highest num for scale
  const findHighestValue = () => {
    const allValues = formattedData.map((item) => item.VALUE_NUM)
    return Math.max(...allValues)
  }
  const highestValue = findHighestValue()
  const scaleY = Math.ceil(Math.abs(highestValue) / 1.0e6) * 1.0e6

  const x = scaleBand([2019, ...range(2021, 2031)].flat(), [
    20,
    dimensions.boundedWidth - 20
  ]).padding(0.2)
  const y = scaleLinear([0, scaleY], [dimensions.boundedHeight, 0])

  const formatMill3 = format('.3s')
  //  const valueFor2019 = formattedData[0].value
  const adjustArrowTip = onTarget ? -4 : 4
  //const labelPos =
  // formattedData[formattedData.length - 1].value > scaleY - 1000000 ? 0 : 30
  return (
    <g>
      <XAxis x={x} maxYear={formattedData[formattedData.length - 1].YEAR} />
      <YAxis y={y} />

      {/* Bars */}
      {formattedData.map((d, i) => (
        <rect
          key={`${d.VALUE_NUM}` + i}
          fill={`var(--brightBlue)`}
          x={x(d.YEAR)}
          y={y(d.VALUE_NUM)}
          height={y(0) - y(d.VALUE_NUM)}
          width={x.bandwidth()}
        />
      ))}

      {/* Totals labels at top of bars */}
      {formattedData.map((d) => (
        <Total
          x={x(d.YEAR) + x.bandwidth() / 2}
          y={y(d.VALUE_NUM) - 10}
          key={d.YEAR}
        >
          {d.VALUE_NUM ? formatMill3(d.VALUE_NUM) : null}
        </Total>
      ))}
      {/* Target bar and label */}
      <g>
        <TargetBar
          x={x(2030)}
          y={y(formattedData[0].VALUE_NUM / 2)}
          width={x.bandwidth()}
          height={y(scaleY - formattedData[0].VALUE_NUM / 2)}
        />
        <TargetLabel
          x={x(2030) + x.bandwidth() / 2}
          y={y(formattedData[0].VALUE_NUM / 2) - 12}
        >
          {formatMill3(formattedData[0].VALUE_NUM / 2)}
        </TargetLabel>
      </g>
      {/* Arrow from first to last bar */}
      <defs>
        <marker
          id="arrowhead"
          markerWidth="7"
          markerHeight="4"
          refX="0"
          refY="2"
          orient="auto"
          fill={`var(--baseBlue)`}
        >
          <polygon points="0 0, 7 2, 0 4" />
        </marker>
      </defs>
      <Line
        x1={x(2019) + x.bandwidth() / 2}
        y1={y(formattedData[0].VALUE_NUM) - y(scaleY)}
        x2={x(2030)}
        y2={y(formattedData[0].VALUE_NUM / 2) - y(scaleY) - 3}
        markerEnd="url(#arrowhead)"
        color={`var(--baseBlue)`}
      />

      <circle
        cx={x(2019) + x.bandwidth() / 2}
        cy={y(formattedData[0].VALUE_NUM) - y(scaleY)}
        fill={`var(--baseBlue)`}
        r={8}
      />
      <text
        x={x(2028)}
        y={y(formattedData[0].VALUE_NUM / 2) - y(scaleY) - 50}
        fontSize={24}
        fontWeight={'bold'}
        fill={`var(--baseBlue)`}
      >
        {'-50%'}
      </text>

      {/* Arrow from first to current bar */}
      <defs>
        <marker
          id="arrowhead2"
          markerWidth="7"
          markerHeight="4"
          refX="2"
          refY="2"
          orient="auto"
          fill={onTarget ? `var(--baseBlue)` : `var(--darkOrange)`}
        >
          <polygon points="0 0, 7 2, 0 4" />
        </marker>
      </defs>
      <Line
        x1={x(2019) + x.bandwidth() / 2}
        y1={y(formattedData[0].VALUE_NUM) - y(scaleY)}
        x2={x(formattedData[formattedData.length - 1].YEAR) + 4}
        y2={
          y(formattedData[formattedData.length - 1].VALUE_NUM) -
          y(scaleY) +
          adjustArrowTip
        }
        markerEnd="url(#arrowhead2)"
        color={onTarget ? `var(--baseBlue)` : `var(--darkOrange)`}
      />

      <circle
        cx={x(2019) + x.bandwidth() / 2}
        cy={y(formattedData[0].VALUE_NUM) - y(scaleY)}
        fill={`var(--baseBlue)`}
        r={8}
      />
      <text
        x={x(formattedData[formattedData.length - 1].YEAR + 1)}
        y={
          y(formattedData[formattedData.length - 1].VALUE_NUM) +
          (onTarget ? 30 : 0)
        }
        fontSize={24}
        fontWeight={'bold'}
        fill={onTarget ? `var(--baseBlue)` : `var(--darkOrange)`}
      >
        {data > 0 ? '+' : ''}
        {`${Math.round(data)}%`}
      </text>
    </g>
  )
}

export default IG21Chart
